import dayjs from "dayjs";
import { EntityModel } from "hateoas-hal-types";
import { Cache } from "swr";
import useRequest, {
  get,
  mutate,
  post,
  prefetch,
  prefetchSearchRequest,
  put,
  useSearchRequest,
} from "../api";
import { SubscriberCustomerInfo } from "../models/SubscriberCustomerInfo.model";
import { Subscription } from "../models/Subscription.model";
import { SubscriptionBeneficiary } from "../models/SubscriptionBeneficiary.model";
import { SubscriptionInvoice } from "../models/SubscriptionInvoice.model";
import SubscriptionPeriodStatus from "../models/SubscriptionPeriodStatus.enum";
import { SubscriptionPlan } from "../models/SubscriptionPlan.model";
import SubscriptionStatus from "../models/SubscriptionStatus.enum";
import SubscriptionTypeCode from "../models/SubscriptionTypeCode.enum";

export const getByCode = async (code: string) => {
  return await get<Subscription>(`${SUBCRIPTIONS_BASE_URL}/${code};idType=registrationCode`);
};

type ActivateCodeInput = {
  code: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};

export type Invoice = {
  issueNumber: string;
  issueDate: Date;
  contents: string;
  fileName: string;
  proforma: boolean;
  amount: number;
};

export type UpdateSubscriptionStatusInput = {
  licenseCount?: number;
  discount?: number;
  prefix?: string;
  start?: Date;
  end?: Date;
  status: SubscriptionStatus;
  invoice?: Invoice;
};

export type GenerateInvoiceInput = {
  licenseCount: number;
  description: string;
  plan: SubscriptionPlan;
  discount: number;
  issueNumber: string;
  issueDate: Date;
  customerInfo: SubscriberCustomerInfo;
  proforma: boolean;
};

export type UpdateSubscriptionPeriodStatusInput = {
  proformaInvoice?: Invoice;
  invoice?: Invoice;
  status: SubscriptionPeriodStatus;
  paymentReference?: string;
};

export const activateCode = async (
  input: ActivateCodeInput
): Promise<EntityModel<SubscriptionBeneficiary>> => {
  return await post(`${SUBCRIPTIONS_BASE_URL}/confirmations`, input);
};

export const confirmCode = async (confirmationId: string, action: string) => {
  await put(`${SUBCRIPTIONS_BASE_URL}/confirmations/${confirmationId}`, action, true);
};

export const generateInvoice = async (
  subscriptionId: string,
  input: GenerateInvoiceInput
): Promise<{
  contents: string;
  fileName: string;
}> => {
  return await post(`${SUBCRIPTIONS_BASE_URL}/${subscriptionId}/invoice`, input, false);
};

export const updateSubscriptionStatus = async (
  subscriptionId: string,
  input: UpdateSubscriptionStatusInput
) => {
  const result = await put(`${SUBCRIPTIONS_BASE_URL}/${subscriptionId}/status`, input);
  mutate(`${SUBCRIPTIONS_BASE_URL}/${subscriptionId};idType=id`, () => result, false);
  return result;
};

export const updateSubscriptionPeriodStatus = async (
  subscriptionId: string,
  periodId: string,
  input: UpdateSubscriptionPeriodStatusInput
) => {
  const result = await put(
    `${SUBCRIPTIONS_BASE_URL}/${subscriptionId}/periods/${periodId}/status`,
    input
  );
  mutate(`${SUBCRIPTIONS_BASE_URL}/${subscriptionId};idType=id`, () => result, false);
  return result;
};

export const SUBCRIPTIONS_BASE_URL = "/subscriptions";
export const INVOICES_BASE_URL = "/invoices";

export const useSubscriptions = () => useSearchRequest<Subscription>(SUBCRIPTIONS_BASE_URL);

export const useInvoices = () => useSearchRequest<SubscriptionInvoice>(INVOICES_BASE_URL);

export const useInvoiceNumber = (proforma: boolean) =>
  useRequest<{ value: string }>(SUBCRIPTIONS_BASE_URL + `/invoice-number?proforma=${proforma}`)
    .data;

export const useDiscount = (planId: number, licenceCount: number) =>
  useRequest<{ value: number }>(
    SUBCRIPTIONS_BASE_URL + `/plans/${planId}/discount?licenceCount=${licenceCount}`
  ).data;

export const fetchDiscount = (planId: number, licenceCount: number) =>
  get<{ value: number }>(
    SUBCRIPTIONS_BASE_URL + `/plans/${planId}/discount?licenceCount=${licenceCount}`
  );

export const formatInvoiceNumber = (invoice?: { issueNumber: string; issueDate: Date }) => {
  return invoice ? `${invoice.issueNumber}/${dayjs(invoice.issueDate).format("DD.MM.YYYY")}` : "";
};

export const prefetchSubscriptions = (searchParams: URLSearchParams, cache: Cache<any>) =>
  prefetchSearchRequest(SUBCRIPTIONS_BASE_URL, searchParams, cache);

export const useSubscription = (id?: string) => {
  const { data } = useRequest<EntityModel<Subscription>>(
    id ? `${SUBCRIPTIONS_BASE_URL}/${id};idType=id` : null
  );
  return data;
};

export const fetchSubscription = (id: string) => {
  return get<EntityModel<Subscription>>(`${SUBCRIPTIONS_BASE_URL}/${id};idType=id`);
};

export const prefetchSubscription = (id: string, cache: Cache<any>) =>
  prefetch(`${SUBCRIPTIONS_BASE_URL}/${id};idType=id`, cache);

export const saveSubscription = async (data: Subscription, subscriptionId?: string) => {
  const result = (
    subscriptionId
      ? await put(`${SUBCRIPTIONS_BASE_URL}/${subscriptionId}`, data)
      : await post(`${SUBCRIPTIONS_BASE_URL}`, data)
  ) as EntityModel<Subscription>;
  mutate(`${SUBCRIPTIONS_BASE_URL}/${subscriptionId}`, () => result, false);
  return result;
};

export const renewSubscription = async (data: { type: SubscriptionTypeCode }, id: string) => {
  await post(`${SUBCRIPTIONS_BASE_URL}/${id}/renew`, data);
  mutate("/user/current");
};

export const subscriptionHref = (id: string): string => `${SUBCRIPTIONS_BASE_URL}/${id}`;
export const invoiceHref = (id: string): string => `${INVOICES_BASE_URL}/${id}`;
