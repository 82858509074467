import { useCallback } from "react";
import Badge from "react-bootstrap/Badge";
import { FormattedMessage } from "react-intl";
import { Cache } from "swr";
import useSWRInfinite from "swr/infinite";
import useRequest, { del, get, mutate, post, prefetch, SearchApiResponse } from "../api";
import { config } from "../constants";
import { hasPrivilege, Privileges, Visible } from "../contexts/auth.context";
import { useSearchContext } from "../contexts/search.context";
import DocumentStats from "../models/DocumentStats";
import DocumentStatus from "../models/DocumentStatus.enum";
import HistoryPeriod from "../models/HistoryPeriod.enum";
import { OpenedDocumentHistoryForDate } from "../models/OpenedDocumentHistoryForDate.model";
import { SearchHit } from "../models/SearchHit.model";
import { UserReaction } from "../models/UserReaction.model";

export const useDocumentStats = () => {
  const { search } = useSearchContext();
  const { data } = useRequest<DocumentStats>(
    search !== undefined ? `/documents/stats${search ? "?" + search : ""}` : null
  );
  return data;
};

export const prefetchDocumentStats = (
  searchParams: URLSearchParams = new URLSearchParams(),
  cache: Cache<any>
) => {
  const query = searchParams.toString();
  return prefetch(`/documents/stats${query ? "?" + query : ""}`, cache);
};

export const useOpenedDocumentHistory = (period: HistoryPeriod, query: string) => {
  const getKey = useCallback(
    (
      pageIndex: number,
      previousPageData: SearchApiResponse<OpenedDocumentHistoryForDate> | null
    ) => {
      if (pageIndex === 0) {
        return `${config.url}/api/user/current/documents/opened/${period}?query=${query}`;
      }
      return previousPageData?._links.next.href || null;
    },
    [period, query]
  );
  const result = useSWRInfinite<SearchApiResponse<OpenedDocumentHistoryForDate>>(getKey);

  const totalCount = result.data && result.data[0] ? result.data[0].page.totalElements : undefined;
  const pageCount = result.data && result.data[0] ? result.data[0].page.totalPages : undefined;
  const complete = result.data === undefined || !totalCount || pageCount === result.data.length;
  const isLoadingMore =
    result.size > 0 && result.data && typeof result.data[result.size - 1] === "undefined";
  return { ...result, hasMore: !complete, totalCount, pageCount, isLoadingMore };
};

export const fetchDocumentStats = () => get<DocumentStats>("/documents/stats");

export const fetchSuggestions = (query: string) =>
  get<SearchHit[]>(`/documents/suggestions?query=${enhanceQuery(query)}&category=keywords`);

export const useSuggestions = (query: string, category?: string) =>
  useRequest<SearchHit[]>(`/documents/suggestions?query=${query}&category=${category}`).data || [];

const enhanceQuery = (query: string) => {
  return query
    .replace(/(тълк\.*р|ТР|(Т\.*|тълк)(\.|\s)\s*(р\.*\s|реш\.*|решение))/i, "Тълкувателно Решение ")
    .replace(/ПП\s*ВС\s*/i, "ПП ВС Постановление ")
    .replace(
      /(тълк\.*п|ТП|(Т\.*|тълк)(\.|\s)\s*(п\.*\s|постановление))/i,
      "Тълкувателно Постановление "
    )
    .replace(/([0-9]+)\/([0-2])([0-9])(\s|$)/i, "$1/20$2$3 ")
    .replace(/([0-9]+)\/([3-9])([0-9])(\s|$)/i, "$1/19$2$3 ")
    .replace(/(Реш|Реш.|Р)\s*([0-9]+\/[0-9]+)(\s|$)/i, "Решение $2 ")
    .replace(/(Опр|Опр.)\s*([0-9]+\/[0-9]+)(\s|$)/i, "Определение $2 ")
    .replace(/(\s|^)(гд|г.д.)/i, "гр.д. ");
};

export const useUserReactions = (documentId?: string) => {
  let { data } = useRequest<{ count: number; reactions: UserReaction[] }>(
    documentId ? `/documents/${documentId}/reactions` : null
  );
  return data || { reactions: [], count: undefined };
};

export const addUserReaction = async (documentId: string) => {
  const reactions = await post(`/documents/${documentId}/reactions`, {});
  await mutate(`/documents/${documentId}/reactions`, async () => reactions, false);
};

export const delUserReaction = async (documentId: string, reactionId: string) => {
  const reactions = await del(`/documents/${documentId}/reactions/${reactionId}`, {});
  await mutate(`/documents/${documentId}/reactions`, async () => reactions, false);
};

export const DocumentStatusBadge = ({ status }: { status?: DocumentStatus }) =>
  status ? (
    <Visible when={hasPrivilege(Privileges.GENERAL_DOCUMENTS_STATUS)}>
      <Badge
        bg={
          status === DocumentStatus.PUBLIC
            ? "success"
            : status === DocumentStatus.DELETED
            ? "danger"
            : status === DocumentStatus.DRAFT
            ? "warning"
            : status === DocumentStatus.FOCUSED
            ? "primary"
            : "info"
        }
        className="document-status-badge"
      >
        <FormattedMessage id={`document.status.${status}`} />
      </Badge>
    </Visible>
  ) : null;
