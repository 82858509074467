import { Recogito } from "@recogito/recogito-js";
import "@recogito/recogito-js/dist/recogito.min.css";
import { EntityModel } from "hateoas-hal-types";
import parse from "html-react-parser";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { v4 as uuid } from "uuid";
import { JudgementAct, JudgementActDomain } from "../../models/JudgementAct.model";
import { useJudgementAct } from "../../services/judgement-act-services";
import { useUserDocumentAnno } from "../../services/user-services";
import DocumentQuickViewLayout from "../DocumentPage/document-quick-view-layout";
import JudgementQuickViewPageActions from "./judgement-quick-view-page-actions";
import "./judgement-quick-view-page.scss";
import { getCategory } from "./judgement-view-page";
import { QuickViewInfoCard, QuickViewOperativeCard } from "./judgmenet-view-page-metadata";
import pagemap from "./pagemap";
import RenderTextWithReferences from "./renderTextWithReferences";

interface JudgementQuickViewPageProps {
  actId: string;
  onClose: () => void;
}

const JudgementQuickViewPage: React.FC<JudgementQuickViewPageProps> = ({ actId, onClose }) => {
  let actIdOnly = actId;
  let actIdReference;
  const delimIdx = actId.indexOf("#");
  if (delimIdx > -1) {
    actIdOnly = actId.substring(0, delimIdx);
    actIdReference = decodeURIComponent(actId.substring(delimIdx + 1));
  }
  const act = useJudgementAct(actIdOnly);
  const location = useLocation();

  return act ? (
    <JudgementViewForm
      act={act}
      onClose={onClose}
      key={location.key}
      highlightRef={actIdReference}
    />
  ) : null;
};

interface JudgementViewFormProps {
  act: EntityModel<JudgementAct>;
  highlightRef?: string;
  onClose: () => void;
}

const JudgementViewForm: FunctionComponent<JudgementViewFormProps> = ({
  act,
  onClose,
  highlightRef,
}) => {
  const [recogito, setRecogito] = useState<any>();

  const id = useMemo(() => uuid(), []);

  useEffect(() => {
    const r = new Recogito({
      content: id,
      disableEditor: true,
      formatter: (anno: any) => {
        return getCategory(anno)?.value !== "Без етикет" ? "categorized" : "";
      },
    });
    r.readOnly = true;

    setRecogito(r);

    return () => r.destroy();
  }, [setRecogito, id]);

  const annotations = useUserDocumentAnno(act.id);

  useEffect(() => {
    if (recogito && annotations) {
      recogito.setAnnotations(annotations.map((a) => JSON.parse(a.body)));
      setTimeout(() => {
        const canvas = document.querySelector(`#quick-view-map-${id}`) as HTMLCanvasElement;
        toggleCanvas(recogito, id);
        if (recogito.pagemap) {
          recogito.pagemap.redraw();
        } else {
          recogito.pagemap = pagemap(canvas, {
            styles: {
              "div.act-text > p": "rgba(0,0,0,0.08)",
              "span.r6o-annotation.categorized": "rgba(0, 129, 204,1)",
              "span.r6o-selection.categorized": "rgba(0, 81, 128,1)",
              "span.r6o-annotation:not(.categorized)": "rgb(255,165,0,.7)",
              "span.r6o-selection:not(.categorized)": "rgba(255,165,0,1)",
              mark: "rgba(134, 52, 235)",
            },
            back: "rgba(0,0,0,0.02)",
            view: "rgba(0,0,0,0.05)",
            drag: "rgba(0,0,0,0.10)",
            interval: null,
            viewport: document.querySelector(`#quick-view-map-${id}`)?.closest(".offcanvas-body"),
          });
        }
        document
          .querySelector(`#quick-view-map-${id}`)
          ?.closest(".offcanvas-body")
          ?.querySelector(".act-text mark")
          ?.scrollIntoView({
            block: "center",
          });
      }, 100);
    }
  }, [recogito, annotations, id]);

  return (
    <DocumentQuickViewLayout
      id={id}
      title={act.title}
      menu={<JudgementQuickViewPageActions act={act} onClose={onClose} />}
      onClose={onClose}
    >
      <div>
        {(act.domain === JudgementActDomain.DOMESTIC ||
          act.domain === JudgementActDomain.ADMINISTRATIVE) && <QuickViewInfoCard act={act} />}

        <QuickViewOperativeCard act={act} />

        <div className="act-text highlight-mode quick-act-body" id={id}>
          {parse(act.contents, {
            replace: (node) => {
              if ((node as any).data) {
                return RenderTextWithReferences(
                  (node as any).data,
                  act.citedReferences,
                  highlightRef
                );
              }
            },
          })}
        </div>
      </div>
    </DocumentQuickViewLayout>
  );
};

export const toggleCanvas = (recogito: any, id: string) => {
  const canvas = document.querySelector(`#quick-view-map-${id}`) as HTMLCanvasElement;
  canvas.style.left = recogito.getAnnotations().length ? "" : "-999px";
  canvas.parentElement!.style.width = recogito.getAnnotations().length ? "24px" : "0";
};

export default JudgementQuickViewPage;
