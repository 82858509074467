import dayjs from "dayjs";
import { CollectionModel, EntityModel } from "hateoas-hal-types";
import memoize from "memoizee";
import { useIntl } from "react-intl";
import useRequest, { ApiResponse, get } from "../api";
import { Privileges, useAuth } from "../contexts/auth.context";
import AllowedByCourt from "../models/AllowedByCourt.enum";
import DocumentAccessMode from "../models/DocumentAccessMode.enum";
import DocumentStatus from "../models/DocumentStatus.enum";
import NewsArticleSection from "../models/NewsArticleSection.enum";
import PasswordResetRequestStatus from "../models/PasswordResetRequestStatus.enum";
import Provision from "../models/Provision.model";
import RefData from "../models/RefData.model";
import { SubscriptionPlan } from "../models/SubscriptionPlan.model";
import SubscriptionStatus from "../models/SubscriptionStatus.enum";
import { useUserDocuments } from "./user-services";

export type LabelValue = {
  label: string;
  value: string;
};

export interface StringData {
  value: string;
}

interface RefDataResponse {
  [key: string]: { content: EntityModel<RefData>[] };
}

const refDataMapperV2 =
  (enumName: string) =>
  (data: RefDataResponse): EntityModel<RefData>[] =>
    data ? data[enumName].content : [];

const refSwrOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export const useJudgementActTypes = (): ApiResponse<EntityModel<RefData>[]> =>
  useRequest<EntityModel<RefData>[]>(
    buildUrl("/ref"),
    refDataMapperV2("judgementActTypes"),
    refSwrOptions
  );

export const useCaseTypes = (): ApiResponse<EntityModel<RefData>[]> =>
  useRequest<EntityModel<RefData>[]>(buildUrl("/ref"), refDataMapperV2("caseTypes"), refSwrOptions);

export const useCourts = (): ApiResponse<EntityModel<RefData>[]> =>
  useRequest<EntityModel<RefData>[]>(buildUrl("/ref"), refDataMapperV2("courts"), refSwrOptions);

export const useDomesticCourts = (): ApiResponse<EntityModel<RefData>[]> => {
  const response = useCourts();
  return {
    error: response.error,
    data: response.data?.filter((item) => item.category === "Български съдилища"),
  };
};

export const useEuCourts = (): ApiResponse<EntityModel<RefData>[]> => {
  const response = useCourts();
  return {
    error: response.error,
    data: response.data?.filter((item) => item.category === "Европейски съдилища"),
  };
};

export const useDomesticJudgementActTypes = (): ApiResponse<EntityModel<RefData>[]> => {
  const response = useJudgementActTypes();
  return {
    error: response.error,
    data: response.data?.filter((item) => item.category === "Български"),
  };
};

export const useEuJudgementActTypes = (): ApiResponse<EntityModel<RefData>[]> => {
  const response = useJudgementActTypes();
  return {
    error: response.error,
    data: response.data?.filter((item) => item.category === "Европейски"),
  };
};

export const useChambers = (): ApiResponse<EntityModel<RefData>[]> =>
  useRequest<EntityModel<RefData>[]>(buildUrl("/ref"), refDataMapperV2("chambers"), refSwrOptions);

export const useActsChambers = (): ApiResponse<EntityModel<RefData>[]> => {
  const response = useChambers();
  return {
    error: response.error,
    data: response.data?.filter((item) => item.category === "ВКС"),
  };
};

export const useAdministrativeActsChambers = (): ApiResponse<EntityModel<RefData>[]> => {
  const response = useChambers();
  return {
    error: response.error,
    data: response.data?.filter((item) => item.category === "ВАС"),
  };
};

export const useProceedings = (): ApiResponse<EntityModel<RefData>[]> => {
  const response = useRequest<EntityModel<RefData>[]>(
    buildUrl("/ref"),
    refDataMapperV2("proceedings"),
    refSwrOptions
  );
  const auth = useAuth();
  const view247 = auth.hasPrivilige(Privileges.EDIT_SUMMARIES);
  return {
    error: response.error,
    data: view247 ? response.data : response.data?.filter((item) => item.id !== 4),
  };
};

export const useActsProceedings = (): ApiResponse<EntityModel<RefData>[]> => {
  const response = useProceedings();
  return {
    error: response.error,
    data: response.data?.filter((item) => item.category === "ВКС"),
  };
};

export const useAdministrativeActsProceedings = (): ApiResponse<EntityModel<RefData>[]> => {
  const response = useProceedings();
  return {
    error: response.error,
    data: response.data?.filter((item) => item.category === "ВАС"),
  };
};

export const useCaseStatuses = (): ApiResponse<EntityModel<RefData>[]> =>
  useRequest<EntityModel<RefData>[]>(
    buildUrl("/ref"),
    refDataMapperV2("caseStatuses"),
    refSwrOptions
  );

export const useGroundOfAppeals = (): ApiResponse<EntityModel<RefData>[]> =>
  useRequest<EntityModel<RefData>[]>(
    buildUrl("/ref"),
    refDataMapperV2("groundOfAppeals"),
    refSwrOptions
  );

export const useNewsArticleCategories = (): ApiResponse<EntityModel<RefData>[]> =>
  useRequest<EntityModel<RefData>[]>(
    buildUrl("/ref"),
    refDataMapperV2("newsArticleCategories"),
    refSwrOptions
  );

export const useDocumentSets = (): ApiResponse<EntityModel<RefData>[]> =>
  useRequest<EntityModel<RefData>[]>(
    buildUrl("/ref"),
    refDataMapperV2("documentSets"),
    refSwrOptions
  );

export const useSummaryTypes = (): ApiResponse<EntityModel<RefData>[]> =>
  useRequest<EntityModel<RefData>[]>("/ref", refDataMapperV2("summaryTypes"), refSwrOptions);

export const useSubscriptionTypes = (): ApiResponse<EntityModel<RefData>[]> =>
  useRequest<EntityModel<RefData>[]>(
    "/ref/subscription-types",
    (data) => data?._embedded.items || [],
    refSwrOptions
  );

export const useSubscriptionPlans = (): ApiResponse<EntityModel<SubscriptionPlan>[]> =>
  useRequest<EntityModel<SubscriptionPlan>[]>(
    "/subscriptions/plans",
    (data) => data?._embedded.items || [],
    refSwrOptions
  );

export const useSubscriptionReferralCodes = (): ApiResponse<EntityModel<RefData>[]> =>
  useRequest<EntityModel<RefData>[]>(
    "/ref/subscription-referral-codes",
    (data) => data?._embedded.items || [],
    refSwrOptions
  );

export const useSubscriptionStatuses = (): ApiResponse<RefData[]> => {
  const intl = useIntl();
  return {
    data: Object.keys(SubscriptionStatus).map((status, idx) => ({
      id: status,
      code: status,
      shortName: intl.formatMessage({ id: `subscription.status.${status}` }),
      name: intl.formatMessage({ id: `subscription.status.${status}` }),
      rank: -1,
    })),
  };
};

export const useSubscriptionOtherFilters = (): ApiResponse<RefData[]> => {
  return {
    data: [
      {
        id: "expiresSoon:true",
        code: "",
        shortName: "Скоро изтичащ",
        name: "Скоро изтичащ",
        rank: -1,
      },
      {
        id: "paymentDueSoon:true",
        code: "",
        shortName: "С наближаващо плащане",
        name: "С наближаващо плащане",
        rank: -1,
      },
    ],
  };
};

export const useUserEnabled = (): ApiResponse<RefData[]> => {
  return {
    data: [
      {
        id: "true",
        code: "",
        shortName: "Активен",
        name: "Активен",
        rank: 1,
      },
      {
        id: "false",
        code: "",
        shortName: "Неактивен",
        name: "Неактивен",
        rank: 2,
      },
    ],
  };
};

export const usePasswordResetRequestExpired = (): ApiResponse<RefData[]> => {
  return {
    data: [
      {
        id: "true",
        code: "",
        shortName: "Да",
        name: "Да",
        rank: 1,
      },
      {
        id: "false",
        code: "",
        shortName: "Не",
        name: "Не",
        rank: 2,
      },
    ],
  };
};

export const usePasswordResetRequestStatuses = (): ApiResponse<RefData[]> => {
  const intl = useIntl();
  return {
    data: Object.keys(PasswordResetRequestStatus).map((status, idx) => ({
      id: status,
      code: status,
      shortName: intl.formatMessage({ id: `password-reset-request.status.${status}` }),
      name: intl.formatMessage({ id: `password-reset-request.status.${status}` }),
      rank: -1,
    })),
  };
};

export const useUserInternal = (): ApiResponse<RefData[]> => {
  return {
    data: [
      {
        id: "true",
        code: "",
        shortName: "Служебен",
        name: "Служебен",
        rank: 1,
      },
      {
        id: "false",
        code: "",
        shortName: "Стандартен",
        name: "Стандартен",
        rank: 2,
      },
    ],
  };
};

export const useProforma = (): ApiResponse<RefData[]> => {
  return {
    data: [
      {
        id: "true",
        code: "",
        shortName: "Проформа",
        name: "Проформа",
        rank: 1,
      },
      {
        id: "false",
        code: "",
        shortName: "Фактура",
        name: "Фактура",
        rank: 2,
      },
    ],
  };
};

export const useNewsArticleSections = (): ApiResponse<RefData[]> => {
  const intl = useIntl();
  return {
    data: Object.keys(NewsArticleSection).map((status, idx) => ({
      id: status,
      code: status,
      shortName: intl.formatMessage({ id: `news-article.section.${status}` }),
      name: intl.formatMessage({ id: `news-article.section.${status}` }),
      rank: -1,
    })),
  };
};

export const useShowInHeader = (): ApiResponse<RefData[]> => {
  return {
    data: [
      {
        id: "true",
        code: "",
        shortName: "Да",
        name: "Да",
        rank: 1,
      },
      {
        id: "false",
        code: "",
        shortName: "Не",
        name: "Не",
        rank: 2,
      },
    ],
  };
};

export const useDocumentStatuses = (): ApiResponse<RefData[]> => {
  const intl = useIntl();
  return {
    data: Object.keys(DocumentStatus).map((status, idx) => ({
      id: status,
      code: status,
      shortName: intl.formatMessage({ id: `document.status.${status}` }),
      name: intl.formatMessage({ id: `document.status.${status}` }),
      rank: -1,
    })),
  };
};

export const usePersonalCategories = (): ApiResponse<RefData[]> => {
  const userDocuments = useUserDocuments();
  return {
    data: Array.from(
      new Set(userDocuments.favourite.filter((doc) => !!doc.category).map((doc) => doc.category))
    )
      .sort()
      .map((category, i) => ({
        id: category!,
        code: category!,
        shortName: category!,
        name: category!,
        rank: -1,
      })),
  };
};

export const useDocumentAccessModes = (): ApiResponse<RefData[]> => {
  const intl = useIntl();
  return {
    data: Object.keys(DocumentAccessMode).map((mode, idx) => ({
      id: mode,
      code: mode,
      shortName: intl.formatMessage({ id: `document.access-mode.${mode}` }),
      name: intl.formatMessage({ id: `document.access-mode.${mode}` }),
      rank: -1,
    })),
  };
};

export const useActYears = (): ApiResponse<RefData[]> => {
  return {
    data: Array.from(Array(new Date().getFullYear() - 1949).keys())
      .map((year) => year + 1950 + "")
      .map((year) => ({
        id: year,
        code: year,
        shortName: year,
        name: year,
        rank: -1,
      }))
      .reverse(),
  };
};

export const useActMonths = (): ApiResponse<RefData[]> => {
  return {
    data: dayjs.months().map((month, idx) => ({
      id: String(idx + 1).padStart(2, "0"),
      code: month,
      shortName: month,
      name: month,
      rank: -1,
    })),
  };
};

export const useOtherFilters = (): ApiResponse<RefData[]> => {
  const auth = useAuth();
  const showHasCommentsFilter = auth.hasPrivilige(Privileges.SEARCH_EXTENDED_FILTERS);
  return {
    data: [
      {
        id: "isFocused:true",
        code: "",
        shortName: "На фокус",
        name: "На фокус",
        rank: -1,
      },
      {
        id: "hasEditorialOpinion:true",
        code: "",
        shortName: "С редакторска бележка",
        name: "С редакторска бележка",
        rank: -1,
      },
      {
        id: "hasDissentingOpinion:true",
        code: "",
        shortName: "С особено мнение",
        name: "С особено мнение",
        rank: -1,
      },
      ...(showHasCommentsFilter
        ? [
            {
              id: "hasComments:true",
              code: "",
              shortName: "Със служебна бележка",
              name: "Със служебна бележка",
              rank: -1,
            },
          ]
        : []),
      {
        id: "isRelatedToEuLegislation:true",
        code: "",
        shortName: "Връзка с европейско законодателство",
        name: "Връзка с европейско законодателство",
        rank: -1,
      },
    ],
  };
};

export const useQuestionOtherFilters = (): ApiResponse<RefData[]> => {
  return {
    data: [
      {
        id: "isFocused:true",
        code: "",
        shortName: "На фокус",
        name: "На фокус",
        rank: -1,
      },
      {
        id: "hasEditorialOpinion:true",
        code: "",
        shortName: "С редакторска бележка",
        name: "С редакторска бележка",
        rank: -1,
      },
      {
        id: "hasDissentingOpinion:true",
        code: "",
        shortName: "С особено мнение",
        name: "С особено мнение",
        rank: -1,
      },
      {
        id: "isRelatedToEuLegislation:true",
        code: "",
        shortName: "Връзка с европейско законодателство",
        name: "Връзка с европейско законодателство",
        rank: -1,
      },
    ],
  };
};

export const useAllowedByCourt = (): ApiResponse<RefData[]> => {
  const intl = useIntl();
  return {
    data: Object.keys(AllowedByCourt).map((status, idx) => ({
      id: status,
      code: status,
      shortName: intl.formatMessage({ id: `judgement-act.allowedByCourt.${status}` }),
      name: intl.formatMessage({ id: `judgement-act.allowedByCourt.${status}` }),
      rank: -1,
    })),
  };
};

export const useActOtherFilters = (): ApiResponse<RefData[]> => {
  const auth = useAuth();
  const showHasSummaryFilter = auth.hasPrivilige(Privileges.EDIT_SUMMARIES);
  const showHasCommentsFilter = auth.hasPrivilige(Privileges.SEARCH_EXTENDED_FILTERS);
  return {
    data: [
      {
        id: "isFocused:true",
        code: "",
        shortName: "На фокус",
        name: "На фокус",
        rank: -1,
      },
      ...(showHasSummaryFilter
        ? [
            {
              id: "hasSummary:true",
              code: "",
              shortName: "С резюме",
              name: "С резюме",
              rank: -1,
            },
          ]
        : [
            {
              id: "hasPublicSummary:true",
              code: "",
              shortName: "С резюме",
              name: "С резюме",
              rank: -1,
            },
          ]),
      {
        id: "hasAnnotations:true",
        code: "",
        shortName: "С редакторско подчертаване",
        name: "С редакторско подчертаване",
        rank: -1,
      },
      {
        id: "hasAiHighlight:true",
        code: "",
        shortName: "С автоматично подчертаване",
        name: "С автоматично подчертаване",
        rank: -1,
      },
      {
        id: "hasPrivateHighlight:true",
        code: "",
        shortName: "С лично подчертаване",
        name: "С лично подчертаване",
        rank: -1,
      },
      {
        id: "hasAnnotationParagraph:true",
        code: "",
        shortName: "С анотация",
        name: "С анотация",
        rank: -1,
      },
      ...(showHasSummaryFilter
        ? [
            {
              id: "hasLinkedSummary:true",
              code: "",
              shortName: "Свързани с резюме",
              name: "Свързани с резюме",
              rank: -1,
            },
          ]
        : [
            {
              id: "hasPublicLinkedSummary:true",
              code: "",
              shortName: "Свързани с резюме",
              name: "Свързани с резюме",
              rank: -1,
            },
          ]),
      ...(showHasSummaryFilter
        ? [
            {
              id: "hasAnnotationParagraph:false",
              code: "",
              shortName: "Без анотация",
              name: "Без анотация",
              rank: -1,
            },
            {
              id: "hasAnnotations:false",
              code: "",
              shortName: "Без подчертаване",
              name: "Без подчертаване",
              rank: -1,
            },
          ]
        : []),
      ...(showHasSummaryFilter
        ? [
            {
              id: "hasSummary:false",
              code: "",
              shortName: "Без резюме",
              name: "Без резюме",
              rank: -1,
            },
          ]
        : []),
      ...(showHasCommentsFilter
        ? [
            {
              id: "hasComments:true",
              code: "",
              shortName: "Със служебна бележка",
              name: "Със служебна бележка",
              rank: -1,
            },
          ]
        : []),
    ],
  };
};

export const useEUActOtherFilters = (): ApiResponse<RefData[]> => {
  const auth = useAuth();
  const showHasSummaryFilter = auth.hasPrivilige(Privileges.EDIT_SUMMARIES);
  const showHasCommentsFilter = auth.hasPrivilige(Privileges.SEARCH_EXTENDED_FILTERS);
  return {
    data: [
      {
        id: "isFocused:true",
        code: "",
        shortName: "На фокус",
        name: "На фокус",
        rank: -1,
      },
      ...(showHasSummaryFilter
        ? [
            {
              id: "hasSummary:true",
              code: "",
              shortName: "С резюме",
              name: "С резюме",
              rank: -1,
            },
          ]
        : [
            {
              id: "hasPublicSummary:true",
              code: "",
              shortName: "С резюме",
              name: "С резюме",
              rank: -1,
            },
          ]),
      {
        id: "hasAnnotations:true",
        code: "",
        shortName: "С редакторско подчертаване",
        name: "С редакторско подчертаване",
        rank: -1,
      },
      {
        id: "hasAiHighlight:true",
        code: "",
        shortName: "С автоматично подчертаване",
        name: "С автоматично подчертаване",
        rank: -1,
      },
      {
        id: "hasPrivateHighlight:true",
        code: "",
        shortName: "С лично подчертаване",
        name: "С лично подчертаване",
        rank: -1,
      },
      ...(showHasSummaryFilter
        ? [
            {
              id: "hasLinkedSummary:true",
              code: "",
              shortName: "Свързани с резюме",
              name: "Свързани с резюме",
              rank: -1,
            },
          ]
        : [
            {
              id: "hasPublicLinkedSummary:true",
              code: "",
              shortName: "Свързани с резюме",
              name: "Свързани с резюме",
              rank: -1,
            },
          ]),
      ...(showHasSummaryFilter
        ? [
            {
              id: "hasAnnotations:false",
              code: "",
              shortName: "Без подчертаване",
              name: "Без подчертаване",
              rank: -1,
            },
          ]
        : []),
      ...(showHasSummaryFilter
        ? [
            {
              id: "hasSummary:false",
              code: "",
              shortName: "Без резюме",
              name: "Без резюме",
              rank: -1,
            },
          ]
        : []),
      {
        id: "preliminaryRuling:true",
        code: "",
        shortName: "С преюдициално запитване",
        name: "С преюдициално запитване",
        rank: -1,
      },
      ...(showHasCommentsFilter
        ? [
            {
              id: "hasComments:true",
              code: "",
              shortName: "Със служебна бележка",
              name: "Със служебна бележка",
              rank: -1,
            },
          ]
        : []),
    ],
  };
};

export const useLatestActFilters = (): ApiResponse<RefData[]> => {
  return {
    data: [
      {
        id: "isAddedToday:true",
        code: "",
        shortName: "Днес",
        name: "Днес",
        rank: -1,
      },
      {
        id: "isAddedYesterday:true",
        code: "",
        shortName: "Вчера",
        name: "Вчера",
        rank: -1,
      },
      {
        id: "isAddedLastTenDays:true",
        code: "",
        shortName: "Последните 10 дни",
        name: "Последните 10 дни",
        rank: -1,
      },
      {
        id: "isRecentlyPublished:true",
        code: "",
        shortName: "Нови",
        name: "Нови",
        rank: -1,
      },
    ],
  };
};

export const fetchReporters = memoize(
  async (name: string) =>
    (
      (await get("/ref/domestic/judgement-acts/reporters", {
        name,
      })) as CollectionModel<StringData>
    )._embedded.items.map(({ value }) => ({
      label: value,
      value,
    })),
  { promise: true }
);

export const fetchEditors = memoize(
  async (name: string) =>
    (
      (await get("/ref/domestic/summaries/editors", {
        name,
      })) as CollectionModel<StringData>
    )._embedded.items.map(({ value }) => ({
      label: value,
      value,
    })),
  { promise: true }
);

export const buildUrl = (url: string): string => url;

export const getProvisionFullTitle = (provision: Provision): string =>
  provision.title +
  (provision.sgIssueNumber && provision.sgYear
    ? Number.parseInt(provision.sgYear) >= 1951 && Number.parseInt(provision.sgYear) <= 1962
      ? ` (ред., Изв. ${provision.sgIssueNumber}/${provision.sgYear} г.)`
      : ` (ред., ДВ, бр. ${provision.sgIssueNumber}/${provision.sgYear} г.)`
    : "");
