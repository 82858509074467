import { EntityModel } from "hateoas-hal-types";
import { useCallback, useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FormProvider, useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";
import SummaryViewContextProvider from "../../contexts/summary-view.context";
import SummaryContextProvider from "../../contexts/summary.context";
import { printSummaryTitle, Summary } from "../../models/Summary.model";
import { useSummary } from "../../services/summary-services";
import DocumentLikeAction from "../DocumentPage/document-like-action";
import DocumentQuickViewLayout from "../DocumentPage/document-quick-view-layout";
import MainInfoCard from "./main-info-card";
import ProvisionsCard from "./provisions-card";
import QuestionCard from "./question-card";
import SummaryQuickViewPageActions from "./summary-quick-view-page-actions";
import { SummaryQuickViewPageLoading } from "./summary-view-page-loading";
import "./summary-view-page.scss";

interface SummaryQuickViewPageProps {
  summaryId: string;
  onClose: () => void;
}

const SummaryQuickViewPage: React.FC<SummaryQuickViewPageProps> = ({ summaryId, onClose }) => {
  const summary = useSummary(summaryId);

  return summary ? (
    <SummaryViewForm summary={summary} onClose={onClose} />
  ) : (
    <SummaryQuickViewPageLoading onClose={onClose} />
  );
};

interface SummaryViewPageProps {
  summary: EntityModel<Summary>;
  onClose: () => void;
}

const SummaryViewForm: React.FC<SummaryViewPageProps> = ({ summary, onClose }) => {
  const defaultSectionToggles = useMemo(() => {
    const toggles = new Map<string, number>();
    summary?.qas.forEach((qa, idx) => toggles.set(`${idx}-answer`, 1));
    return toggles;
  }, [summary]);
  const [sectionToggles, setSectionToggles] = useState(defaultSectionToggles);
  const getSectionToggleStatus = useCallback(
    (section: string) => sectionToggles.get(section) || 0,
    [sectionToggles]
  );
  const setSectionToggleStatus = useCallback(
    (section: string, status?: any) => {
      const newState = new Map(sectionToggles);
      const oldValue = getSectionToggleStatus(section);
      const newValue = status === undefined ? (oldValue ? oldValue + 1 : 2) : status;
      if (newValue) {
        newState.set(section, newValue);
      } else {
        newState.delete(section);
      }
      setSectionToggles(newState);
    },
    [getSectionToggleStatus, setSectionToggles, sectionToggles]
  );

  const setToggleAllStatus = useCallback(
    (sections: string[], status = 1) => {
      const newState = new Map(sectionToggles);
      sections.forEach((section) => {
        const oldValue = getSectionToggleStatus(section);
        const newValue = status === undefined ? (oldValue ? oldValue + 1 : 2) : status;
        if (newValue) {
          newState.set(section, newValue);
        } else {
          newState.delete(section);
        }
      });

      setSectionToggles(newState);
    },
    [getSectionToggleStatus, setSectionToggles, sectionToggles]
  );

  const methods = useForm({
    defaultValues: summary,
  });
  const id = useMemo(() => uuid(), []);
  return (
    <FormProvider {...methods}>
      <SummaryViewContextProvider toggleAll={setToggleAllStatus}>
        <SummaryContextProvider summary={summary}>
          <DocumentQuickViewLayout
            id={id}
            title={printSummaryTitle(summary)}
            className="summary-page"
            menu={<SummaryQuickViewPageActions summary={summary} onClose={onClose} />}
            onClose={onClose}
          >
            <div>
              <h5>
                {printSummaryTitle(summary)}
                <DocumentLikeAction id={summary.id} />
              </h5>
              <h6>{summary.shortTitle && `[${summary.shortTitle}]`}</h6>

              <Row className="info-cards-row">
                <Col md={4}>
                  <ProvisionsCard />
                </Col>
                <Col md={8}>
                  <MainInfoCard />
                </Col>
              </Row>
              {summary.qas.map((qa, idx) => (
                <QuestionCard
                  key={qa.id}
                  idx={idx}
                  qa={qa}
                  provisions={summary?.provisions}
                  questionCount={summary?.qas.length}
                  summaryActDate={summary.relatedJudgementAct.actDate}
                  getSectionToggleStatus={getSectionToggleStatus}
                  setSectoinToggleStatus={setSectionToggleStatus}
                />
              ))}
            </div>
          </DocumentQuickViewLayout>
        </SummaryContextProvider>
      </SummaryViewContextProvider>
    </FormProvider>
  );
};

export default SummaryQuickViewPage;
