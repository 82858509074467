import dayjs from "dayjs";
import { EntityModel } from "hateoas-hal-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import AutoSizedTextArea from "../../components/AutoSizeTextArea";
import NotAuthorized from "../../components/NotAuthorized";
import { RefDataSelect } from "../../components/Select/select";
import SelectSingle from "../../components/SimpleSelect/select-single";
import { hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import ViewEditPageContextProvider, {
  useViewEditPageContext,
} from "../../contexts/view-edit-page.context";
import { Subscription } from "../../models/Subscription.model";
import SubscriptionStatus from "../../models/SubscriptionStatus.enum";
import {
  useSubscriptionPlans,
  useSubscriptionReferralCodes,
} from "../../services/ref-data-services";
import { useSubscription } from "../../services/subscription-services";
import EditPageLayout from "../DocumentPage/edit-page-layout";
import SubscriptionEditPageActions from "./subscription-edit-page-actions";
import BeneficiariesSection from "./subscription-edit-page-beneficiaries";
import CustomerInfoSection from "./subscription-edit-page-customer-info";
import InvoicesSection from "./subscription-edit-page-invoices";
import PeriodsSection from "./subscription-edit-page-periods";
import SubscriptionPageLoading from "./subscription-page-loading";
interface SubscriptionEditPageProps {
  subscription?: EntityModel<Subscription>;
}

const SubscriptionEditPage = () => {
  const { subscriptionId } = useParams();
  const subscription = useSubscription(subscriptionId);

  return subscription || !subscriptionId ? (
    <ViewEditPageContextProvider defaultMode={subscriptionId ? "view" : "edit"}>
      <SubscriptionEditForm subscription={subscription} />
    </ViewEditPageContextProvider>
  ) : (
    <SubscriptionPageLoading />
  );
};

const SubscriptionEditForm: React.FC<SubscriptionEditPageProps> = ({ subscription }) => {
  const serverData = subscription;
  const intl = useIntl();

  const methods = useForm<EntityModel<Subscription>>({
    shouldUnregister: false,
    defaultValues: serverData || {
      status: SubscriptionStatus.REQUESTED,
      code: `L-${dayjs(new Date()).format("YYYYMMDD")}-${generateId(5).toUpperCase()}`,
    },
  });

  const { control, register } = methods;

  const { data: subscriptionPlans } = useSubscriptionPlans();
  const { data: referralCodes } = useSubscriptionReferralCodes();

  const { mode } = useViewEditPageContext();

  const status = subscription?.status || SubscriptionStatus.REQUESTED;

  return (
    <FormProvider {...methods}>
      <EditPageLayout
        menu={<SubscriptionEditPageActions />}
        title={
          subscription
            ? subscription.code
            : intl.formatMessage({ id: "subscription-edit.page.add-new-title" })
        }
        className="subscription-page"
      >
        <Form className="edit-form" autoComplete="off">
          <Row>
            <Col md="4">
              <Form.Group controlId="code">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.code" />
                </Form.Label>
                <Form.Control type="text" {...register("code")} readOnly />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="type">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.type" />
                </Form.Label>
                <Controller
                  name="plan"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={subscriptionPlans}
                      getOption={(plan) => ({
                        label: plan.type.name,
                        value: plan.id,
                      })}
                      value={value}
                      onChange={onChange}
                      isDisabled={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group controlId="status">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.status" />
                </Form.Label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={Object.keys(SubscriptionStatus)}
                      getOption={(status) => ({
                        label: intl.formatMessage({ id: `subscription.status.${status}` }),
                        value: status,
                      })}
                      value={value}
                      onChange={onChange}
                      isDisabled
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Form.Group controlId="prefix">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.prefix" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("prefix")}
                  readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group controlId="discount">
                <Form.Label>
                  <FormattedMessage id="generate-invoice.form.discount" />
                </Form.Label>
                <Controller
                  name="discount"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSingle
                      options={Array.from(Array(100).keys())}
                      getOption={(option) => ({
                        label: option + "%",
                        value: option,
                      })}
                      isDisabled={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group controlId="licenseCount">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.licenseCount" />
                </Form.Label>
                <Form.Control
                  defaultValue="1"
                  type="text"
                  {...register("licenseCount")}
                  readOnly={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="referralCode">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.referralCode" />
                </Form.Label>
                <Controller
                  name="referralCode"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RefDataSelect
                      onChange={onChange}
                      value={[value]}
                      shadowed
                      options={referralCodes}
                      isDisabled={mode === "view" || status !== SubscriptionStatus.REQUESTED}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="notes">
                <Form.Label>
                  <FormattedMessage id="edit-subscription.form.notes" />
                </Form.Label>
                <Controller
                  name="notes"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoSizedTextArea onChange={onChange} value={value} minHeight={80} />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <CustomerInfoSection />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <BeneficiariesSection />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <PeriodsSection />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <InvoicesSection />
            </Col>
          </Row>
        </Form>
      </EditPageLayout>
    </FormProvider>
  );
};

const generateId = (length: number) => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const ProtectedSubscriptionEditPage = () => {
  return (
    <Visible when={hasPrivilege(Privileges.EDIT_USERS)} fallback={NotAuthorized}>
      <SubscriptionEditPage />
    </Visible>
  );
};

export default ProtectedSubscriptionEditPage;
