/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AsyncTypeahead, Highlighter, Menu, MenuItem } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { useDeviceSelectors } from "react-device-detect";
import { FaBuffer, FaFilter, FaProductHunt, FaSearch } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import { HelpLink, HELP_LINK_SEARCH_TAB } from "../../../components/HelpLink/help-link";
import SearchFieldController from "../../../components/SearchFieldController/search-field-controller";
import { useSearchContext } from "../../../contexts/search.context";
import { SearchHit } from "../../../models/SearchHit.model";
import {
  DocumentStatusBadge,
  fetchSuggestions,
  prefetchDocumentStats,
} from "../../../services/document-services";
import { useQuery, useSearchCategory } from "../search-utils";
import SavedSearches from "./saved-searches";
import "./search-input.scss";

const SearchInput = () => {
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  return isMobile ? <SearchInputMobile /> : <SearchInputNonMobile />;
};

const SearchInputNonMobile = () => {
  const navigate = useNavigate();

  const searchCategory = useSearchCategory();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<SearchHit[]>([]);

  const handleSearch = async (query: string) => {
    setIsLoading(true);

    setOptions(await fetchSuggestions(query));

    setIsLoading(false);
  };

  const handleSuggestion = async (ref: any, newValue: any, value: any, onChange: any) => {
    ref.hideMenu();
    const isOnLandingPage = (searchCategory as string) === "search";
    const { navigate } = await onChange(
      {
        query: [newValue],
        sort: "relevance,desc",
      },
      false
    );
    navigate(isOnLandingPage ? "/search/acts" : pathname);
  };

  const handleFilter = async (ref: any, filterName: any, newValue: any, onChange: any) => {
    ref.hideMenu();
    const isOnLandingPage = (searchCategory as string) === "search";
    const { navigate } = await onChange(
      {
        [filterName]: [...(query[filterName] || []), newValue],
      },
      false
    );
    navigate(isOnLandingPage ? "/search/acts" : pathname);
  };

  const query = useQuery();
  const [ref, setRef] = useState<any>();
  useEffect(() => {
    if (ref) {
      ref.clear();
    }
  }, [query, ref]);

  const { pathname } = useLocation();
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const { opened } = useSearchContext();

  useEffect(() => {
    if (ref && !isMobile && opened.length === 0) {
      setTimeout(() => {
        ref.focus({
          preventScroll: true,
        });
      }, 100);
    }
  }, [pathname, ref, isMobile, opened]);

  const onRefChange = useCallback((node: any) => {
    // ref value changed to node
    setRef(node); // e.g. change ref state to trigger re-render
  }, []);

  const { formatMessage } = useIntl();

  return (
    <SearchFieldController
      name="query"
      preload={prefetchDocumentStats}
      render={({ field: { onChange, value } }) => (
        <InputGroup className="mb-0 search-input">
          <Button
            style={{ paddingRight: "0" }}
            variant="light"
            onClick={async () => {
              if (!ref.state.text) {
                return;
              }
              const isOnLandingPage = (searchCategory as string) === "search";
              const { navigate } = await onChange(
                {
                  query: [...value, ref.state.text],
                  sort: "relevance,desc",
                },
                false
              );
              navigate(isOnLandingPage ? "/search/acts" : pathname);
            }}
          >
            <FaSearch style={{ rotate: "90deg" }} />
            <HelpLink articleId={HELP_LINK_SEARCH_TAB} />
          </Button>
          <AsyncTypeahead
            ref={onRefChange}
            filterBy={() => true}
            id="async-example"
            isLoading={isLoading}
            labelKey="text"
            minLength={3}
            onSearch={handleSearch}
            options={options}
            placeholder="Търсене по ключови думи"
            promptText={formatMessage({ id: "search-input.enter-search-word" })}
            emptyLabel={formatMessage({ id: "search-input.search-in-progress" })}
            searchText={formatMessage({ id: "search-input.search-in-progress" })}
            useCache={false}
            autoFocus={false}
            defaultInputValue={value[0] || ""}
            defaultSelected={undefined}
            onKeyDown={async (event: any) => {
              if (event.key === "Enter" && event.target.value) {
                ref.hideMenu();
                const isOnLandingPage = (searchCategory as string) === "search";
                const { navigate } = await onChange(
                  {
                    query: [...value, event.target.value],
                    sort: "relevance,desc",
                  },
                  false
                );
                navigate(isOnLandingPage ? "/search/acts" : pathname);
              }
            }}
            renderMenuItemChildren={(option, props) => (
              <div
                title={option.text}
                onClick={() => {
                  if (option.category === "keyword") {
                    handleSuggestion(ref, option.text, value, onChange);
                  } else if (option.category === "filter") {
                    const [filterName, filterValue] = option.id.split(":");
                    handleFilter(ref, filterName, filterValue, onChange);
                  } else {
                    navigate(`/${option.category}/${option.id}`);
                  }
                }}
              >
                {option.category === "keyword" && (
                  <span className="float-start">
                    <FaSearch style={{ rotate: "90deg" }} />
                  </span>
                )}
                {option.category === "filter" && (
                  <span className="float-start">
                    <FaFilter />
                  </span>
                )}
                {option.id.indexOf("#") > 0 && (
                  <span className="float-start">
                    <FaProductHunt />
                  </span>
                )}
                {option.category !== "keyword" &&
                  option.category !== "filter" &&
                  option.id.indexOf("#") < 1 && (
                    <span className="float-start">
                      <FaBuffer />
                    </span>
                  )}
                {option.text}
                {option.category !== "keyword" &&
                  option.category !== "filter" &&
                  option.id.indexOf("#") < 1 && (
                    <span className="float-end">
                      <DocumentStatusBadge status={option.status} />
                    </span>
                  )}
              </div>
            )}
          />
          <SavedSearches />
        </InputGroup>
      )}
    />
  );
};

const SearchInputMobile = () => {
  const navigate = useNavigate();

  const searchCategory = useSearchCategory();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<SearchHit[]>([]);

  const handleSearch = async (query: string) => {
    setIsLoading(true);

    setOptions(await fetchSuggestions(query));

    setIsLoading(false);
  };

  const { formatMessage } = useIntl();

  const [ref, setRef] = useState<any>();
  useEffect(() => {
    if (ref) {
      setTimeout(() => {
        ref.focus();
      }, 100);
    }
  }, [ref]);

  const onRefChange = useCallback((node: any) => {
    // ref value changed to node
    setRef(node); // e.g. change ref state to trigger re-render
  }, []);

  const [showInput, setShowInput] = useState(false);
  return (
    <>
      <SearchFieldController
        name="query"
        preload={prefetchDocumentStats}
        render={({ field: { onChange, value } }) => (
          <InputGroup className="mb-0 search-input">
            <Button variant="light" onClick={() => {}}>
              <FaSearch style={{ rotate: "90deg" }} />
            </Button>
            <AsyncTypeahead
              onFocus={(e) => {
                e.preventDefault();
                setShowInput(true);
              }}
              isLoading={false}
              onSearch={() => {}}
              options={[]}
              placeholder="Търсене по ключови думи"
            />
            <SavedSearches />
          </InputGroup>
        )}
      />
      <Modal
        id="search-input-modal"
        show={showInput}
        placement="bottom"
        fullscreen
        centered
        restoreFocus={false}
        animation={false}
      >
        <Modal.Body>
          <SearchFieldController
            name="query"
            preload={prefetchDocumentStats}
            render={({ field: { onChange, value } }) => (
              <InputGroup className="mb-0 search-input">
                <Button variant="light" onClick={() => setShowInput(false)}>
                  <FiArrowLeft />
                </Button>
                <AsyncTypeahead
                  ref={onRefChange}
                  filterBy={() => true}
                  isLoading={isLoading}
                  labelKey="text"
                  minLength={3}
                  onSearch={handleSearch}
                  options={options}
                  promptText={formatMessage({ id: "search-input.enter-search-word" })}
                  emptyLabel=""
                  searchText=""
                  open
                  onKeyDown={async (event: any) => {
                    if (event.key === "Enter" && event.target.value) {
                      const isOnLandingPage = (searchCategory as string) === "search";
                      const { navigate } = await onChange(
                        {
                          query: [...value, event.target.value],
                          sort: "relevance,desc",
                        },
                        false
                      );
                      navigate(isOnLandingPage ? "/search/acts" : pathname);
                      setShowInput(false);
                    }
                  }}
                  useCache={false}
                  autoFocus={true}
                  defaultInputValue=""
                  defaultSelected={undefined}
                  renderMenu={(results, menuProps, state) => {
                    return (
                      <Menu {...menuProps}>
                        {!state.text && (
                          <Menu.Header>
                            {formatMessage({ id: "search-input.enter-search-word" })}
                          </Menu.Header>
                        )}
                        {state.text && state.text.length >= 3 && (
                          <>
                            <span
                              className="d-flex align-items-center"
                              style={{ gap: "5px" }}
                              onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const isOnLandingPage = (searchCategory as string) === "search";
                                const { navigate } = await onChange(
                                  {
                                    query: [...value, state.text],
                                    sort: "relevance,desc",
                                  },
                                  false
                                );
                                navigate(isOnLandingPage ? "/search/acts" : pathname);
                                setShowInput(false);
                              }}
                            >
                              <FaSearch /> <span>Търсене по фраза "{state.text}"</span>
                            </span>

                            <Menu.Header>БЪРЗИ ВРЪЗКИ</Menu.Header>
                            {results
                              .filter((r) => r.category !== "keyword" && r.category !== "filter")
                              .map((r, index) => (
                                <MenuItem key={index} option={r} position={index}>
                                  <div
                                    title={r.text}
                                    onClick={() => navigate(`/${r.category}/${r.id}`)}
                                  >
                                    <Highlighter search={state.text}>{r.text}</Highlighter>
                                    <span className="float-end">
                                      <DocumentStatusBadge status={r.status} />
                                    </span>
                                  </div>
                                </MenuItem>
                              ))}
                          </>
                        )}
                      </Menu>
                    );
                  }}
                />
              </InputGroup>
            )}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SearchInput;
