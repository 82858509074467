import {
  all,
  hasName,
  hasPrivilege,
  oneOf,
  Privileges,
  Visible,
} from "../../contexts/auth.context";
import { useSummaryContext } from "../../contexts/summary.context";
import { isPublishedStatus } from "../../models/DocumentStatus.enum";
import { printSummaryTitle } from "../../models/Summary.model";
import { fetchSummaryPdf, fetchSummaryRtf } from "../../services/summary-services";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";
import DocumentCollectionActions from "../DocumentPage/document-collection-actions";
import DocumentEditAction from "../DocumentPage/document-edit-action";
import SummarySearchAction from "./summary-search-action";

const SummaryViewPageMenu = () => {
  const { summary } = useSummaryContext();

  return (
    <DocumentActionsContainer
      document={{
        id: summary.id,
        title: printSummaryTitle(summary),
      }}
    >
      <SummarySearchAction />
      <Visible
        when={oneOf(
          all(hasName(summary.editor?.name), () => !isPublishedStatus(summary.status)),
          all(hasPrivilege(Privileges.EDIT_ALL_SUMMARIES), () => !summary.restrictedEdit),
          hasPrivilege(Privileges.PROTECT_SUMMARIES)
        )}
      >
        <DocumentEditAction />
      </Visible>
      <DocumentCollectionActions
        showPrintExport
        showRtfExport
        dataToExport={async (options) => {
          return options.rtf
            ? (await fetchSummaryRtf(summary.id)).data
            : (await fetchSummaryPdf(summary.id)).data;
        }}
        accessMode={summary.accessMode}
      />
      <DocumentCloseAction />
    </DocumentActionsContainer>
  );
};

export default SummaryViewPageMenu;
