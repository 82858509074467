import { EntityModel } from "hateoas-hal-types";
import { FunctionComponent } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { FaPlus, FaProductHunt } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useSWRConfig } from "swr";
import { hasPrivilege, Privileges, useAuth } from "../../contexts/auth.context";
import { useLoadingProgress } from "../../contexts/loading-progress.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { JudgementAct } from "../../models/JudgementAct.model";
import { fetchJudgementActPdf } from "../../services/judgement-act-services";
import { prefetchSummary, summaryHref } from "../../services/summary-services";
import DocumentAction from "../DocumentPage/document-action";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";
import DocumentCollectionActions from "../DocumentPage/document-collection-actions";
import DocumentEditAction from "../DocumentPage/document-edit-action";

interface JudgementViewPageActionsProps {
  act: EntityModel<JudgementAct>;
}

const JudgementViewPageActions: FunctionComponent<JudgementViewPageActionsProps> = ({ act }) => {
  const navigate = useNavigate();

  const addSummary = async () =>
    navigate(`/summaries/new`, {
      state: { actId: act?.id, title: act?.title },
    });

  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const progress = useLoadingProgress();
  const { cache } = useSWRConfig();

  const { setSelectedActId } = useSelectedActContext();

  const viewSummary = async () => {
    progress.start();
    try {
      await prefetchSummary(act.summaries[0].id, cache);
    } finally {
      progress.complete();
    }
    if (isMobileOnly) {
      navigate(summaryHref(act.summaries[0].id));
    } else {
      setSelectedActId("summary:" + act.summaries[0].id);
    }
  };

  const { currentUser } = useAuth();
  const hasEditPrivilege = hasPrivilege(Privileges.EDIT_SUMMARIES)(currentUser);
  const hasSummary = act.summaries?.length > 0;
  const intl = useIntl();
  return (
    <DocumentActionsContainer document={act}>
      {hasEditPrivilege && <DocumentEditAction />}
      {hasEditPrivilege && !hasSummary && (
        <DocumentAction
          handler={addSummary}
          icon={<FaPlus />}
          label={intl.formatMessage({ id: "judgement-act-actions.add-summary" })}
        />
      )}
      {hasSummary && (
        <DocumentAction
          handler={viewSummary}
          icon={<FaProductHunt />}
          label={intl.formatMessage({ id: "judgement-act-actions.view-summary" })}
        />
      )}
      {/* <DocumentHighlightAction /> */}
      <DocumentCollectionActions
        showPrintExport
        hasPrintExportOptions
        showHighlight
        dataToExport={async (options) =>
          (
            await fetchJudgementActPdf(act.id, act.domain, {
              ...options,
              contents: document.getElementById("act-body")!.innerHTML,
            })
          ).data
        }
        accessMode={act.accessMode}
      />
      <DocumentCloseAction />
    </DocumentActionsContainer>
  );
};

export default JudgementViewPageActions;
