import { EntityModel } from "hateoas-hal-types";
import AllowedByCourt from "./AllowedByCourt.enum";
import { Bulletin } from "./Bulletin.model";
import CitedReference from "./CitedReference.model";
import DocumentAccessMode from "./DocumentAccessMode.enum";
import DocumentStatus from "./DocumentStatus.enum";
import LexebraEditor from "./LexebraEditor.model";
import RefData from "./RefData.model";
import { LinkedSummary } from "./ReferencedDocument";

export enum JudgementActDomain {
  DOMESTIC = "DOMESTIC",
  ADMINISTRATIVE = "ADMINISTRATIVE",
  EU = "EU",
  CONSTITUTIONAL = "CONSTITUTIONAL",
  SUPREME_COURT = "SUPREME_COURT",
}

export interface JudgementAct {
  id: string;
  title: string;
  reporters: string[];
  summaries: LinkedSummary[];
  actDate: string;
  actNumber: string;
  court: RefData;
  actType: RefData;
  chamber: RefData;
  euChamber: string;
  caseType: RefData;
  caseNumber: string;
  caseYear: string;
  caseReference: string;
  caseWord: string;
  caseSrc: string;
  caseInternalId: string;
  contents: string;
  src: string;
  status: DocumentStatus;
  accessMode: DocumentAccessMode;
  relatedActs: EntityModel<{ id: string; title: string; status: DocumentStatus }>[];
  similarActs: EntityModel<{ id: string; title: string; status: DocumentStatus }>[];
  citedReferences: CitedReference[];
  // citedFrom: CitedReference[];
  highlights: {
    contents?: string;
  };
  domain: JudgementActDomain;
  comments: string;
  proceeding: RefData;
  timeReading: number;
  preliminaryRuling: boolean;
  sgIssueNumber: string;
  sgYear: string;
  hasAnnotations: boolean;
  hasAiAnnotations: boolean;
  annotatedBy: LexebraEditor;
  highlightedBy: LexebraEditor[];
  annotationSource: string;
  documentSets: RefData[];
  allowedByCourt: AllowedByCourt;
  editorAnnotation: string;
  hasDissentingOpinion: boolean;
  operativePart: string;
  hasEditorAnnotation: boolean;
  topics: RefData[];
  caseMatter: string;
  caseAdminBody: string;
  caseStatus: RefData;
  bulletin?: Bulletin;
}
