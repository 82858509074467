import { useState } from "react";
import Form from "react-bootstrap/Form";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useSummaryEditContext } from "../../contexts/summary-edit.context";
import Provision from "../../models/Provision.model";
import { Summary } from "../../models/Summary.model";
import AddProvisionModal from "./add-provision.modal";
import ProvisionsTable from "./provisions.table";

type ProvisionsSectionProps = {
  summaryTitle: string;
};

const ProvisionsSection: React.FC<ProvisionsSectionProps> = ({ summaryTitle }) => {
  const { control } = useFormContext<Summary>();

  const [selectedForEdit, setSelectedForEdit] = useState<Provision>();

  const { modeAddProvisionModal: mode, setModeAddProvisionModal: setMode } =
    useSummaryEditContext();

  return (
    <Controller
      name="provisions"
      control={control}
      render={(renderProps) => (
        <Form.Group controlId="provisions">
          <Form.Label>
            <FormattedMessage
              id="edit-summary.form.provisions"
              values={{ count: renderProps.field.value.length }}
            />
            <AddProvisionModal
              selectedForEdit={selectedForEdit}
              setSelectedForEdit={setSelectedForEdit}
              append={(newValue) => {
                if (!selectedForEdit) {
                  if (renderProps.field.value.findIndex((item) => newValue.id === item.id) === -1) {
                    renderProps.field.onChange([...renderProps.field.value, newValue]);
                  }
                } else {
                  renderProps.field.onChange(
                    renderProps.field.value.map((item) =>
                      item.id === selectedForEdit.id ? newValue : item
                    )
                  );
                }
              }}
              remove={(id) => {
                renderProps.field.onChange(renderProps.field.value.filter((val) => val.id !== id));
              }}
              summaryTitle={summaryTitle}
              mode={mode}
              setMode={setMode}
            />
          </Form.Label>
          <ProvisionsTable {...renderProps} setSelectedForEdit={setSelectedForEdit} />
        </Form.Group>
      )}
    />
  );
};

export default ProvisionsSection;
