/* eslint-disable react/style-prop-object */
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Col, InputGroup, Row, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { InlineLinkButton } from "../../components/Button/button";
import SelectSingle from "../../components/SimpleSelect/select-single";
import { Subscription } from "../../models/Subscription.model";
import {
  generateInvoice,
  GenerateInvoiceInput,
  useDiscount,
  useInvoiceNumber,
} from "../../services/subscription-services";

type GenerateInvoiceProps = {
  proforma?: boolean;
  period?: boolean;
  subscription: Subscription;
  onGenerate: (
    data?: GenerateInvoiceInput & {
      contents: string;
      fileName: string;
      amount: number;
    }
  ) => void;
};

const GenerateInvoice: React.FC<GenerateInvoiceProps> = ({
  subscription,
  proforma = false,
  period = false,
  onGenerate,
}) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty },
    watch,
    reset,
  } = useForm<GenerateInvoiceInput>({
    defaultValues: {
      licenseCount: subscription.licenseCount,
      discount: subscription.discount,
      plan: subscription.plan,
      description: subscription.plan.type.name,
      customerInfo: subscription.subscriber.customerInfo,
      issueNumber: "",
      issueDate: new Date(),
      proforma,
    },
  });

  const invoiceNumber = useInvoiceNumber(proforma);
  useEffect(() => {
    setValue("issueNumber", invoiceNumber?.value || "");
  }, [invoiceNumber, setValue]);

  const formData = watch();
  const defaultDiscount = useDiscount(subscription.plan.id, formData.licenseCount);
  useEffect(() => {
    defaultDiscount !== undefined && proforma && setValue("discount", defaultDiscount.value);
  }, [defaultDiscount, proforma, setValue]);

  const [generatedInvoice, setGeneratedInvoice] = useState<{
    contents: string;
    fileName: string;
  }>();

  const intl = useIntl();
  const onSumbit = async (data: GenerateInvoiceInput) => {
    try {
      const pdfData = await generateInvoice(subscription.id, data);
      setGeneratedInvoice(pdfData);
      onGenerate({
        ...data,
        amount: totalPrice,
        contents: "data:application/pdf;base64," + pdfData.contents,
        fileName: pdfData.fileName,
      });
      reset({}, { keepValues: true, keepDirty: false });
      toast.success(
        intl.formatMessage(
          {
            id: "generate-invoice.modal.toasts.success",
          },
          data
        )
      );
    } catch (e) {
      console.log(e);
      toast.error(intl.formatMessage({ id: "generate-invoice.modal.toasts.error" }, data));
    }
  };

  const onChangeDecorator =
    (onChange: any) =>
    (...args: any[]) => {
      setGeneratedInvoice(undefined);
      onGenerate(undefined);
      onChange(...args);
    };

  const regularPricePerMonth = formData.plan.price * formData.licenseCount;
  const regularPrice = regularPricePerMonth * formData.plan.periodDuration;
  const priceBeforeTaxes = (regularPrice * (100 - formData.discount)) / 100;
  const discount = regularPrice - priceBeforeTaxes;
  const tax = 0.0 * priceBeforeTaxes; //0.2
  const totalPrice = priceBeforeTaxes + tax;

  return (
    <Form className="edit-form" autoComplete="off">
      <Row>
        <Col md="7">
          <Form.Group controlId="reference">
            <Form.Label>
              {proforma ? (
                <FormattedMessage id="generate-invoice.form.reference-proforma" />
              ) : (
                <FormattedMessage id="generate-invoice.form.reference" />
              )}
            </Form.Label>
            <InputGroup className="mb-3">
              <Controller
                name="issueNumber"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Form.Control
                    type="text"
                    value={value}
                    onChange={onChangeDecorator(onChange)}
                    style={{ textAlign: "right" }}
                  />
                )}
              />

              <InputGroup.Text>/{dayjs(formData.issueDate).format("DD.MM.YYYY")}</InputGroup.Text>
              <Button
                onClick={handleSubmit(onSumbit)}
                disabled={(isSubmitting || !formData.issueNumber || !isDirty) && !!generatedInvoice}
              >
                {proforma ? (
                  <FormattedMessage id="button.generate-proforma-invoice" />
                ) : (
                  <FormattedMessage id="button.generate-invoice" />
                )}
              </Button>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-0">
        <Col md="12">
          {generatedInvoice ? (
            <InlineLinkButton
              onClick={() => {
                const pdfWindow = window.open("");
                pdfWindow &&
                  pdfWindow.document.write(
                    "<html><head><title>" +
                      generatedInvoice.fileName +
                      "</title></head><body><iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                      encodeURI(generatedInvoice.contents) +
                      "'></iframe></body></html>"
                  );
              }}
            >
              {generatedInvoice.fileName}
            </InlineLinkButton>
          ) : (
            <strong>
              {proforma ? (
                <FormattedMessage id="generate-invoice.form.no-generated-proforma-invoice" />
              ) : (
                <FormattedMessage id="generate-invoice.form.no-generated-invoice" />
              )}
            </strong>
          )}
        </Col>
      </Row>
      {/* <Row>
        <Col md="12">
          <Form.Group controlId="description">
            <Form.Label>
              <FormattedMessage id="generate-invoice.form.description" />
            </Form.Label>
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Form.Control
                  type="text"
                  value={value}
                  onChange={onChangeDecorator(onChange)}
                  rows={3}
                  style={{ height: "50px" }}
                  as="textarea"
                  readOnly={!proforma}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row> */}
      <Row>
        <Col md="4">
          <Form.Group controlId="licenseCount">
            <Form.Label>
              <FormattedMessage id="generate-invoice.form.licenseCount" />
            </Form.Label>
            <Controller
              name="licenseCount"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectSingle
                  options={Array.from({ length: 50 }, (_, i) => i + 1)}
                  getOption={(option) => ({
                    label: `${option}`,
                    value: option,
                  })}
                  value={value}
                  onChange={(value) => {
                    onChangeDecorator(onChange)(value);
                  }}
                  isDisabled={!proforma || period}
                />
              )}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="regularPrice">
            <Form.Label>
              <FormattedMessage id="generate-invoice.form.regularPrice" />
            </Form.Label>
            <Form.Control type="text" {...register("plan.price")} readOnly />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="discount">
            <Form.Label>
              <FormattedMessage id="generate-invoice.form.discount" />
            </Form.Label>
            <Controller
              name="discount"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectSingle
                  options={Array.from(Array(100).keys())}
                  getOption={(option) => ({
                    label: option + "%",
                    value: option,
                  })}
                  value={value}
                  onChange={onChangeDecorator(onChange)}
                  isDisabled={!proforma || period}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md="12">
          <Table>
            <tbody>
              <tr>
                <td>Редовна цена:</td>
                <td>
                  {regularPrice} лв. ({formData.plan.periodDuration} месеца x {regularPricePerMonth}{" "}
                  лв.)
                </td>
              </tr>
              <tr>
                <td>Отстъпка:</td>
                <td>
                  <FormattedNumber
                    value={!!formData.discount ? discount : 0}
                    style="currency"
                    currency="BGN"
                  />{" "}
                  (<FormattedNumber value={formData.discount / 100 || 0} style="percent" />)
                </td>
              </tr>
              <tr>
                <td>Данъчна основа:</td>
                <td>
                  <FormattedNumber value={priceBeforeTaxes} style="currency" currency="BGN" />
                </td>
              </tr>
              <tr>
                <td>ДДС:</td>
                <td>
                  <FormattedNumber value={tax} style="currency" currency="BGN" />
                </td>
              </tr>
              <tr>
                <td>Крайна цена:</td>
                <td className="fw-bold">
                  <FormattedNumber value={totalPrice} style="currency" currency="BGN" />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Form>
  );
};

export default GenerateInvoice;
