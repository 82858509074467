/* eslint-disable no-underscore-dangle */
import { Button } from "react-bootstrap";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import CitedReference from "../../models/CitedReference.model";
const RenderTextWithReferences = (
  text: string,
  citedReferences: CitedReference[],
  highlightedRef?: string
) => {
  const { setSelectedActId } = useSelectedActContext();

  let result: any[] = [text];
  citedReferences.forEach((citedRef) => {
    const newResult: any[] = [];
    result.forEach((token: string) => {
      if (typeof token !== "string") {
        newResult.push(token);
      } else {
        let remaining = token;
        while (remaining.indexOf(citedRef.reference) > -1) {
          const idx = remaining.indexOf(citedRef.reference);
          newResult.push(remaining.substr(0, idx));
          const btn = (
            <Button
              variant="link"
              size="sm"
              key={citedRef.relatedAct.id}
              className={`inline-link`}
              style={{ display: "contents" }}
              onClick={() => {
                setSelectedActId(citedRef.relatedAct.id);
              }}
            >
              {citedRef.reference}
            </Button>
          );
          newResult.push(highlightedRef === citedRef.reference ? <mark>{btn}</mark> : btn);
          remaining = remaining.substr(idx + citedRef.reference.length);
        }
        newResult.push(remaining);
      }
    });
    result = newResult;
  });

  return <>{result}</>;
};

export const RenderTextWithReferencesV2 = (text: string, citedReferences: CitedReference[]) => {
  let result = text;
  citedReferences.forEach((citedRef) => {
    result = result.replaceAll(
      citedRef.reference,
      `<button type="button" onClick="const myEvent = new CustomEvent('selectedAct', {detail: {id: '${citedRef.relatedAct.id}'},bubbles: true,cancelable: true,composed: false});document.dispatchEvent(myEvent);" class="inline-link btn btn-link btn-sm" style="display: contents;">${citedRef.reference}</button>`
    );
  });

  return result;
};

export default RenderTextWithReferences;
