/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useState } from "react";
import Card from "react-bootstrap/Card";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaArrowCircleRight } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { useSWRConfig } from "swr";
import { InlineLinkButton } from "../../components/Button/button";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { useSummaryContext } from "../../contexts/summary.context";
import Provision from "../../models/Provision.model";
import { Summary } from "../../models/Summary.model";
import { getProvisionFullTitle } from "../../services/ref-data-services";
import { prefetchSummaries } from "../../services/summary-services";
import { shortenUrl } from "../../services/url-services";

const ProvisionsCard = () => {
  const { summary } = useSummaryContext();
  const [seeAll, setSeeAll] = useState(false);
  const provision_conflicts = new Set();
  summary.provisions.forEach((provision) => {
    let provision_root = provision.title;
    if (provision_root.indexOf("-") > 0) {
      provision_root = provision_root.substr(0, provision_root.indexOf("-"));
    }
    if (provision_root.indexOf("(") > 0) {
      provision_root = provision_root.substr(0, provision_root.indexOf("(")).trim();
    }
    let candidate_occurences = 0;
    summary.provisions.forEach((candidate) => {
      if (candidate.title !== provision.title) {
        if (candidate.title.indexOf(provision_root) > -1) {
          provision_conflicts.add(candidate.title);
        }
      } else {
        candidate_occurences += 1;
      }
    });
    if (candidate_occurences > 1) {
      provision_conflicts.add(provision.title);
    }
  });
  const { setSelectedActId } = useSelectedActContext();
  return (
    <Card className="info-card">
      <Card.Body>
        <span>
          <FormattedMessage id="view-summary.labels.provisions" />
        </span>
        <ul>
          {summary.provisions.slice(0, !seeAll ? 4 : undefined).map((provision) => (
            <li key={provision.id}>
              <div>
                <OverlayTrigger
                  trigger="click"
                  placement="auto"
                  rootClose
                  overlay={(props) => <ProvisionTooltip provision={provision} {...props} />}
                >
                  <span>
                    <InlineLinkButton
                      onClick={(е: any) => {
                        е.preventDefault();
                      }}
                    >
                      {provision_conflicts.has(provision.title)
                        ? getProvisionFullTitle(provision)
                        : provision.title}
                    </InlineLinkButton>
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="auto"
                  rootClose
                  overlay={(props) => (
                    <Tooltip id="related-acts-provision-tooltip" {...props}>
                      Свързана практика
                    </Tooltip>
                  )}
                >
                  <span>
                    <InlineLinkButton
                      onClick={async (е: any) => {
                        е.preventDefault();
                        setSelectedActId("provision:" + provision.id);
                      }}
                    >
                      <FaArrowCircleRight
                        className="text-muted"
                        style={{ opacity: 0.3, marginTop: "-4px", marginLeft: "3px" }}
                      />
                    </InlineLinkButton>
                  </span>
                </OverlayTrigger>
              </div>
            </li>
          ))}
        </ul>
        {summary.provisions.length > 4 && (
          <InlineLinkButton
            onClick={() => setSeeAll(!seeAll)}
            className="text-decoration-underline"
          >
            {seeAll ? (
              <FormattedMessage id="button.show-less" />
            ) : (
              <FormattedMessage id="button.show-more" />
            )}
          </InlineLinkButton>
        )}
      </Card.Body>
    </Card>
  );
};

const ShowRelatedActsLink: React.FC<{ provisions: Provision[] }> = ({ provisions }) => {
  const { cache } = useSWRConfig();
  const [relatedActsCount, setRelatedActsCount] = useState<number>(0);
  const params = useMemo(() => {
    const params = new URLSearchParams();
    provisions.forEach((provision) => {
      params.append("relatedProvision", provision.title);
    });
    return params;
  }, [provisions]);

  useEffect(() => {
    async function f() {
      const result = await prefetchSummaries(params, cache);

      setRelatedActsCount(result?.[0].page.totalElements || 0);
    }
    f();
  }, [cache, params]);
  const navigate = useNavigate();
  return (
    <InlineLinkButton
      onClick={async () => {
        const q = await shortenUrl(params.toString());
        navigate(`/search/summaries?q=${q}`);
      }}
    >
      Свързана практика({relatedActsCount})
    </InlineLinkButton>
  );
};

const ProvisionTooltip: React.FC<any> = React.forwardRef(({ provision, popper, ...props }, ref) => {
  return (
    <Tooltip
      id={provision.id}
      ref={ref as any}
      {...props}
      className={`${props.className} provision-tooltip`}
    >
      <strong>{getProvisionFullTitle(provision)}</strong>
      {provision?.text && <div dangerouslySetInnerHTML={{ __html: provision?.text }} />}
    </Tooltip>
  );
});

const ProvisionLinkTooltip: React.FC<any> = React.forwardRef(
  ({ provision, popper, ...props }, ref) => {
    const { cache } = useSWRConfig();
    const [result, setResult] = useState<any>();
    useEffect(() => {
      async function f() {
        const params = new URLSearchParams();
        params.append("relatedProvision", provision.title);
        const result = await prefetchSummaries(params, cache);
        setResult(result);
        console.log(result);
        popper.scheduleUpdate();
      }
      f();
    }, [cache, provision.title, popper]);
    const navigate = useNavigate();
    return result ? (
      <Tooltip
        id={provision.id + "-link"}
        ref={ref as any}
        {...props}
        className={`${props.className} provision-tooltip provision-tooltip-xl`}
      >
        <strong>Свързана практика</strong>
        {result?.[0].page.totalElements > 1 ? (
          <>
            {result?.[0]._embedded?.items?.slice(0, 5).map((summary: Summary) => (
              <div key={summary.id} className="mt-3">
                <strong>{summary.relatedJudgementAct.title}</strong>
                <div>{summary.shortTitle}</div>
              </div>
            ))}
            <InlineLinkButton
              onClick={async () => {
                const params = new URLSearchParams();
                params.append("relatedProvision", provision.title);
                const q = await shortenUrl(params.toString());
                navigate(`/search/summaries?q=${q}`);
              }}
              className="mt-3 mb-3 d-flex justify-content-center w-100"
            >
              Виж всички({result?.[0].page.totalElements})
            </InlineLinkButton>
          </>
        ) : (
          <span>Няма свързана практика</span>
        )}
      </Tooltip>
    ) : null;
  }
);

export default ProvisionsCard;
